<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
      <q-form ref="editForm1">
        <c-card title="조치내용 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn 
                v-show="imprEditable&&isOld" 
                :isSubmit="isDelete"
                :url="deleteUrl"
                :param="impr"
                mappingType="DELETE"
                label="삭제" 
                icon="remove"
                @beforeAction="removeImpr"
                @btnCallback="removeCallback" />
              <c-btn 
                v-show="imprEditable" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="impr"
                :mappingType="mappingType"
                label="저장" 
                icon="save" 
                @beforeAction="saveImpr"
                @btnCallback="saveCallback" />
              <c-btn 
                v-show="imprEditable&&isOld" 
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="impr"
                mappingType="PUT"
                label="완료" 
                icon="check" 
                @beforeAction="completeImpr"
                @btnCallback="completeCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-text
                :editable="imprEditable"
                :required="true"
                label="제목"
                name="ibmTitle"
                v-model="impr.ibmTitle">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-textarea
                :editable="imprEditable"
                :required="true"
                label="조치내용"
                name="actionContents"
                v-model="impr.actionContents">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <c-datepicker
                :editable="imprEditable"
                label="조치완료일"
                name="actionCompleteDate"
                v-model="impr.actionCompleteDate"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
      <q-form ref="editForm2" :disabled="!imprEditable">
        <c-card title="조치 사진" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-12">
              <c-upload 
                :editable="imprEditable"
                :attachInfo="attachBeforeInfo"
                label="개선 전 사진">
              </c-upload>
            </div>
            <div class="col-12">
              <c-upload 
                :attachInfo="attachAfterInfo"
                :editable="imprEditable"
                label="개선 후 사진">
              </c-upload>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fm-impr-risk-reduce-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          isSearch: false,
          ram4mAssessImprId: '',
          relationTableKey: '',
          requestContents: '',
        }
      },
    },
    returnData: {
      type: Object,
      default: function() {
        return {
          col1: null,
        }
      },
    }
  },
  data() {
    return {
      impr: {
        ram4mAssessImprId: '',  // 시나리오별 Punch-list 번호
        ram4mAssessScenarioId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ibmTitle: '',  // 제목
        stepFlag: '',  // 완료여부
        actionCompleteDate: '',  // 조치완료일
        actionContents: '',  // 조치내용
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: '4M_IMPR_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        taskClassCd: '4M_IMPR_AFTER',
        taskKey: '',
      },
      mappingType: 'PUT',
      editable: true,
      detailUrl: '',
      saveUrl: '',
      completeUrl: '',
      isSave: false,
      isDelete: false, // 삭제 flag
      isComplete: false,
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.ram4mAssessImprId)
    },
    disabled() {
      return this.popupParam.isSearch || this.impr.stepFlag === 'Y';
    },
    imprEditable() {
      return this.editable && !this.disabled
    },
    deleteUrl() {
      return this.$format(transactionConfig.ram.fm.impr.delete.url, this.popupParam.ram4mAssessImprId);
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.ram.fm.impr.get.url
      this.saveUrl = transactionConfig.ram.fm.impr.update.url
      this.completeUrl = transactionConfig.ram.fm.impr.complete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.ram4mAssessImprId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.impr, _result.data);

          this.attachBeforeInfo.taskKey = this.$_.clone(this.impr.ram4mAssessImprId)
          this.attachAfterInfo.taskKey = this.$_.clone(this.impr.ram4mAssessImprId)

          this.returnData.col1 = this.impr;
        },);
      } else {
        this.$set(this.impr, 'actionCompleteDate', this.$comm.getToday())
        this.$set(this.impr, 'ram4mAssessScenarioId', this.popupParam.relationTableKey)
        this.$set(this.impr, 'ibmTitle', this.popupParam.requestContents)
        this.$set(this.impr, 'stepFlag', 'N')
      }
    },
    /**
     * 요청&접수 상세 저장
     */
    saveImpr() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.ram.fm.impr.update.url
        this.mappingType = 'PUT'
      } else {
        this.saveUrl = transactionConfig.ram.fm.impr.insert.url
        this.mappingType = 'POST'
      }
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.regUserId = this.$store.getters.user.userId
              this.impr.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.$set(this.popupParam, 'ram4mAssessImprId', result.data)
      this.$set(this.attachBeforeInfo, 'taskKey', result.data)
      this.$set(this.attachAfterInfo, 'taskKey', result.data)
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 완료 저장
     */
    completeImpr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.impr.chgUserId = this.$store.getters.user.userId
              
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.popupParam.ram4mAssessImprId = result.data
      this.attachBeforeInfo.isSubmit = uid()
      this.attachAfterInfo.isSubmit = uid()
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /**
     * 삭제
     */
    removeImpr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isDelete = !this.isDelete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('closePopup', this.returnData, 'delete');
    },
  }
};
</script>